import React from "react"
import { AudioPlayerGroup } from "src/components/AudioPlayer"
import TitlePage from "src/components/TitlePage"
import CustomGrid, { Section, Group } from "src/components/CustomGrid"
import { GRIDS } from "src/util"
import { useArticles } from "src/hooks"
import parse from "html-react-parser"
import { BodyTextWrap, TitleContrast } from "src/atoms/typography"
import { Bookshelf } from "src/atoms"
import NextChapter from "../../components/NextChapter"
import { AnimatePresence, motion } from "framer-motion"

const Chapter5 = () => {
  const articles = useArticles({ articleCategory: 9050 })
  const { list } = articles
  if (list.length)
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3.5 }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <TitlePage
              title={list[0]?.Title}
              subtitle={list[0].Lead}
              description={parse(list[0].Content)}
            />
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[1].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[1].Content)}
                  </BodyTextWrap>
                  <AudioPlayerGroup list={list.slice(2, 4)} />
                </div>
                <CustomGrid
                  photos={[list[4]]}
                  grid={GRIDS.p0}
                  heightRatio={1.36}
                />
              </CustomGrid>
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[5].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[5].Content)}
                  </BodyTextWrap>
                </div>
                <CustomGrid
                  photos={[list[6], list[7]]}
                  grid={GRIDS.p1}
                  heightRatio={0.68}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid>
                  <AudioPlayerGroup list={list.slice(8, 12)} />
                </CustomGrid>
              </Group>
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[12].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[12].Content)}
                  </BodyTextWrap>
                </div>
                <CustomGrid
                  photos={[list[13], list[14], list[15]]}
                  grid={GRIDS.p15}
                  heightRatio={0.68}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid>
                  <AudioPlayerGroup list={list.slice(16, 20)} />
                </CustomGrid>
              </Group>
            </Section>
            <Section>
              <CustomGrid>
                <div>
                  <TitleContrast>{list[20].Title}</TitleContrast>
                  <BodyTextWrap style={{ marginBottom: "3rem" }}>
                    {parse(list[20].Content)}
                  </BodyTextWrap>
                </div>
                <CustomGrid
                  photos={[list[21]]}
                  grid={GRIDS.p0}
                  heightRatio={0.62}
                />
              </CustomGrid>
              <Group marginT="4rem">
                <CustomGrid>
                  <AudioPlayerGroup list={list.slice(22, 24)} />
                </CustomGrid>
              </Group>
              <CustomGrid
                photos={list.slice(24, 33)}
                grid={GRIDS.p4}
                heightRatio={0.77}
              />
            </Section>
            <Section>
              <Bookshelf />
              <CustomGrid grid={GRIDS.p4}>
                <AudioPlayerGroup list={list.slice(34, 37)} />
              </CustomGrid>
              <Group marginB="4rem">
                <CustomGrid
                  photos={list.slice(37, 43)}
                  grid={GRIDS.p4}
                  heightRatio={0.52}
                />
              </Group>
              <CustomGrid grid={GRIDS.p4}>
                <AudioPlayerGroup list={list.slice(43, 46)} />
              </CustomGrid>
              <CustomGrid
                photos={list.slice(46, 49)}
                grid={GRIDS.p4}
                heightRatio={0.28}
              />
            </Section>
          </div>
          <NextChapter title="Śmierć" href="/smierc" />
        </motion.div>
      </AnimatePresence>
    )
  return null
}

export default Chapter5
