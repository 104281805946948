import React from "react";
import SEO from "../components/seo";
import Chapter5 from "src/views/Chapter5";

const IndexPage = () => {
  return (
    <>
      <SEO title="Pokolenie Kolumbów i dramat wojny" description="Zaangażowanie Krzysztofa Kamila Baczyńskiego w działalność konspiracyjną Szarych Szeregów i Armii Krajowej. Udział w Powstaniu Warszawskim." image={require('images/seo/5.jpg')}/>
      <Chapter5 />
    </>
  );
};

export default IndexPage;
